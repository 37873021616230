/*
This class represents all the modifications that the state goes through.
All calls to these fuctions HAVE to go through actions.
All functions modify a state object that can be used in the views and components.

Like the Actions we might want to think about splitting these mutations out into their own files
Ex. Athlets in a file, Teams stuff in a file.
Then combine them here
*/

import athlete from "../api/athlete";

export default {
	getAge(state, payload) {
		var today = new Date();
		var birthDate = new Date(payload.athlete.dob);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		payload.athlete.age = age;
	},
	getCompGroup(state, payload) {
		var today = new Date();
		var todayMonth = today.getMonth();
		var todayYear = today.getFullYear();
		var birthDate = new Date(payload.athlete.dob);
		//We use August here because its the end of the regular season for all diciplines
		if (todayMonth > 8) {
			todayYear + 1; //New season starts in September
		}
		var compAge = todayYear - birthDate.getFullYear();
		switch (true) {
			case compAge <= 11:
				return "D";
			case (compAge == 12 || compAge == 13):
				return "C";
			case (compAge == 14 || compAge == 15):
				return "B";
			case (compAge == 16 || compAge == 17):
				return "A";
			case (compAge == 18 || compAge == 19):
				return "JR";
			case compAge >= 20:
				return "Open";
		}
	},
	deafultRoute: (state) => {
		state.routes = state.routes.slice(0, 4); // Be sure to have only 1 route
	},
	updateRouting: (state, payload) => {
		state.routes = state.routes.slice(0, payload.depth);
		var newPath = {
			path: payload.path,
			name: payload.name
		}
		state.routes.push(newPath);
	},
	updateSession: (state, payload) => {
		var success = false;

		state.athlete.allSessions.forEach(sessionType => {
			if (sessionType.type == payload.type.toLowerCase()) {
				sessionType.sessions.forEach(session => {
					if (session.sessionID == payload.chartData.sessionID) {
						session.series = payload.series;
						session.finishDate = payload.finishDate;
						success = true;
					}
				})
			}
		});
		return success;
	},
	updateSessions: (state, payload) => {
		var success = false;

		state.athlete.allSessions.forEach(sessionType => {
			if (sessionType.type == payload.sessionType.toLowerCase()) {
				sessionType.sessions = payload.sessions;
				success = true;
			}
		});
		return success;
	},
	addSession: (state, payload) => {
		var success = false;

		if (state.athlete.allSessions.length > 0) {
			state.athlete.allSessions.forEach(sessionType => {
				if (sessionType.type.toLowerCase() == payload.type.toLowerCase()) {
					sessionType.sessions.push(payload.session);
					success = true;
				}
			});
			if(!success){
				state.athlete.allSessions.push({type:payload.type,sessions:[payload.session]});
			}
		} else {
			//First session ever
			var newSession = {
				type: payload.type.toLowerCase(),
				sessions: [payload.session]
			}
			state.athlete.allSessions.push(newSession);

		}
		return success;
	},
	setTeamState: (state, team) => {
		state.team = team;
	},
	setTeamsState: (state, teams) => {
		state.teams = teams;
	},
	setAthletesFromTeam: (state, team) => {
		state.athletes = team.athletes;
	},
	setAthletesState: (state, athletes) => {
		state.athletes = athletes;
	},
	setAthleteState: (state, athlete) => {
		state.athlete = athlete;
	},
	setPracticeState: (state, payload) => {
		payload.team.practices.forEach(practice => {
			if (payload.practiceID == practice.id) {
				state.practice = practice;
			}
		});
	},
	setTargets: (state, targets) => {
		//TODO combine global Targets and team based targets; Phase n
		state.targets = targets.sort();
	},
	setDrills: (state, drills) => {
		state.drills = drills;
	},
	setDrillTypes: (state, drillTypes) => {
		//we are actually going to add it onto the drill state
		state.drillTypes = drillTypes
	}
}
