<template>
  <div>
    <h1>Practices</h1>
    <b-container fluid>
      <b-row>
        <b-col sm="5" md="6" class="my-1">
          <b-form-group
            label="Per page"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            label-for="perPageSelect"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="7" md="6" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            variant="purple"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>

    <b-table
      striped
      hover
      :current-page="currentPage"
      :per-page="perPage"
      :items="dataItems"
      responsive="sm"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      variant="purple"
    >
      <template v-slot:cell(date)="data">
        <router-link
          variant="purple"
          :to="`/${teamName}/Practice/${data.item.id}`"
          >{{ data.value }}</router-link
        >
      </template>

      <template v-slot:cell(numDrills)="data">
        <div>{{ getNumOfDrills(data.item) }}</div>
      </template>

      <template v-slot:cell(finished)="data">
        <div>{{ finished(data.item.date) }}</div>
      </template>

      <template v-slot:cell(actions)="row">
        <b-button size="sm" @click="row.toggleDetails"
          >{{ row.detailsShowing ? "Hide" : "Show" }} Drills</b-button
        >
      </template>

      <template v-slot:row-details="row">
        <b-card>
          <b-row>
            <b-col>
              <DrillTable
                :dataItems="row.item.drills"
                :teamName="teamName"
                :fields="drillFields"
              />
            </b-col>
          </b-row>
        </b-card>
      </template>
    </b-table>
    <b-container fluid>
      <b-row>
        <b-col sm="5" md="6" class="my-1">
          <b-form-group
            label="Per page"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            label-for="perPageSelect"
            variant="purple"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              variant="purple"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="7" md="6" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            variant="purple"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { globalUtil } from "../mixins/globalUtil.js";
import { practiceUtil } from "../mixins/practiceUtil.js";
import PracticeChart from "@/components/practiceChart.vue";
import DrillTable from "@/components/drillTable.vue";

import "../assets/sass/components/_tables.scss";

export default {
  mixins: [globalUtil, practiceUtil],
  data() {
    //This is how you limit what fields are shown
    return {
      sortBy: "date",
      sortDesc: true,
      fields: [
        {
          key: "date",
          label: "Date",
          sortable: true,
          formatter: "formatDate",
        },
        {
          key: "numDrills",
          label: "# of Drills",
        },
        { key: "finished", label: "Finished", sortable: true },
        { key: "actions", label: "Actions" },
      ],
      drillFields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "targets",
          label: "Targets",
        },
        { key: "difficultyRating", label: "Difficulty", sortable: true },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
    };
  },
  components: {
    PracticeChart,
    DrillTable,
  },
  props: {
    dataItems: Array,
    teamName: String,
  },
  mounted() {
    this.totalRows = this.dataItems.length;
    //TODO go through dataItems and figureout # of drills and If the practice is in the past
    this.addItemFields();
  },
  methods: {
    addItemFields() {
      this.dataItems.forEach((item) => {
        //TODO if needed
      });
    },
    formatDate(value) {
      return this.getFormattedDate(new Date(value));
    },
    finished(date) {
      //Function in practice mixin
      return this.isFinished(date) ? "Yes" : "No";
    },
    getNumOfDrills(practice) {
      return practice.drills.length;
    },
  },
};
</script>
