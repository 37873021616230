<!--
HightCharts chart component.
Takes in a JSON object and creates a highchart out of it
TODO Add dynamic chart types to be passed in

-->
<template class="chart-template">
	<div>
		<b-icon-x-square class="clickable deleteIcon sessionDeleteIcon" variant="secondary" @click="showDeleteModal()">
		</b-icon-x-square>
		<b-modal v-model="showDeleteForm" id="modalDeleteSession" ref="modal" title="Delete Session" :hideFooter="true">
			<p>Are you sure you want to delete this session?</p>
			<b-button class="btn" variant="danger" v-on:click="$emit('deleteSession', chartData.sessionID);">Delete
			</b-button>
			<b-button class="btn btn-secondary" @click="$bvModal.hide('modalDeleteSession')">Cancel</b-button>
		</b-modal>
		<figure class="highcharts-figure">
			<div v-bind:id="'container-' + chartData.sessionID"></div>
			<p class="highcharts-description"></p>
		</figure>
		<button v-if="chartData.finishDate == null" @click="resumeSession()" class="btn"
			:class="[showSessionForm ? 'btn-secondary' : 'btn-purple']">Resume Session</button>
		<b-modal v-model="showSessionForm" id="modalResumeSession" ref="modal" title="Resume Session" :hideFooter="true"
			@hidden="handleOk">
			<ResumeSession :chartData="chartData" :sessionType="sessionType" :athlete="athlete" />
		</b-modal>
	</div>
</template>

<script>
import { mapState } from "vuex";
import "../assets/sass/components/_chart.scss";
import ResumeSession from "@/components/resumeSession.vue";

export default {
	data() {
		//This is how you limit what fields are shown
		return {
			fields: [{ key: "name", label: "Full Name" }, "age"],
			showSessionForm: false,
			showDeleteForm: false,
			session: Object
		};
	},
	components: {
		ResumeSession
	},
	props: {
		chartData: Object,
		sessionType: String,
		athlete: Object
	},
	computed: {
		...mapState(["sessionTypes"])
	},
	mounted() {
		this.setSession();
		var obj = this.buildObject();
		Highcharts.chart("container-" + this.chartData.sessionID, obj);
	},
	methods: {
		setSession() {
			this.sessionTypes.forEach(session => {
				if (this.sessionType === session.type.toLowerCase()) {
					this.session = session;
				}
			});
		},
		showDeleteModal() {
			this.showDeleteForm = true;
		},
		resumeSession() {
			this.showSessionForm = true;
		},
		handleOk(bvModalEvt) {
			// Trigger submit handler
			var obj = this.buildObject();
			Highcharts.setOptions({});
			Highcharts.chart("container-" + this.chartData.sessionID, obj);
		},
		//name: Name of the Series
		getSeriesDescription(type, name) {
			//TODO? Might not need
		},
		buildObject() {
			if (this.sessionType.toLowerCase() == "hangboard") {
				return this.buildRowChart();
			} else {
				return this.buildPolarChart(this.sessionType.toLowerCase());
			}
		},
		buildRowChart() {
			var weightData = this.chartData.data.weight;
			return {
				chart: {
					type: "column"
				},
				title: {
					text: this.chartData.subType
				},
				xAxis: this.session.xAxis,
				yAxis: this.session.yAxis,
				tooltip: {
					shared: true
				},
				plotOptions: {
					series: {
						color: "#8000ff"
					},
					column: {
						zones: [{
							value: 3, // This value is currently looking at y-Value, need it to look at weight
							color: "#B266FF"
						}, {
							value: 5,
							color: "#9933FF"
						}, {
							value: 7,
							color: "#8000FF"
						}, {
							value: 9,
							color: "#6600CC"
						}, {
							value: 11,
							color: "#4C0099"
						}]
					},
					pointFormatter: function () {
						var i;
						var weightValue;
						var points = this.series.points;
						var seriesIndex = this.series.index + 1;
						//Issue here with seriesIndex. Since we are using the data rows and not series, the data is rolled up differently, so the index increments with each point, which it should not.
						for (i = 0; i < points.length; i += 1) {
							if (this.index == i) {
								//console.log("seriesIndex: "+seriesIndex+", i: "+i);
								weightValue = weight[i + 1][seriesIndex];
							}
						}
						//console.log("--------------");
						return '<span style="color:' + this.series.color + '">\u25CF</span> ' + this.series.name + ': <b>' + this.y + ' at ' + weightValue + ' lbs</b><br />';

					}
				},
				tooltip: {
					shared: true,
					backgroundColor: "#ccc",
					color: "#fff",
					borderColor: "white",
					borderRadius: 10,
					borderWidth: 1,
					xDateFormat: "%b %e, %Y",
					headerFormat: '<span style="font-size: 10px">{point.key}</span><br/>'
				},

				data: {
					dateFormat: this.session.data.dateFormat,
					rows: this.chartData.data.rows
				}
			};
		},
		buildPolarChart(type) {
			if (type == "progress") {
				return {
					chart: {
						polar: true,
						height: 400,
						width: 360
					},
					responsive: {
						rules: [
							{
								condition: {
									maxWidth: 1200,
									minWidth: 320
								}
							}
						]
					},
					title: {
						text: "Started on " + this.chartData.startDate
					},

					subtitle: {
						text:
							this.chartData.finishDate == null
								? "Unfinished"
								: "Finished on " + this.chartData.finishDate
					},
					xAxis: this.session.xAxis,

					yAxis: this.session.yAxis,

					tooltip: {
						backgroundColor: "#ccc",
						color: "#fff",
						borderColor: "white",
						borderRadius: 10,
						borderWidth: 1,
						formatter: function () {
							var s = "<b>" + this.x + "</b>";
							s +=
								'<br/><span style="color:' +
								this.point.color +
								'">\u25CF</span> ' +
								this.point.series.name +
								": " +
								this.point.y;
							return s;
						}
					},
					series: [
						{
							type: "area",
							name: this.chartData.series[0].name,
							description:
								"Represents climbs that are vertical or less then vertical",
							color: "#ac52d3",
							fillOpacity: 0.5,
							data: this.chartData.series[0].data
						},
						{
							type: "area",
							name: this.chartData.series[1].name + " ",
							description: "Represents climbs that are primarly overhanging",
							color: "#71e877",
							fillOpacity: 0.5,
							data: this.chartData.series[1].data
						}
					]
				};
			}
			else {
				return {
					chart: {
						polar: true,
						height: 400,
						width: 360
					},
					responsive: {
						rules: [
							{
								condition: {
									maxWidth: 1200,
									minWidth: 320
								}
							}
						]
					},
					title: {
						text: "Started on " + this.chartData.startDate
					},

					subtitle: {
						text:
							this.chartData.finishDate == null
								? "Unfinished"
								: "Finished on " + this.chartData.finishDate
					},
					xAxis: this.session.xAxis,

					yAxis: this.session.yAxis,

					tooltip: {
						backgroundColor: "#ccc",
						color: "#fff",
						borderColor: "white",
						borderRadius: 10,
						borderWidth: 1,
						formatter: function () {
							var s = "<b>" + this.x + "</b>";
							s +=
								'<br/><span style="color:' +
								this.point.color +
								'">\u25CF</span> ' +
								this.point.series.name +
								": v" +
								this.point.y;
							return s;
						}
					},
					series: [
						{
							type: "area",
							name: this.chartData.series[0].name,
							description:
								"Represents climbs that are vertical or less then vertical",
							color: "#ac52d3",
							fillOpacity: 0.5,
							data: this.chartData.series[0].data
						},
						{
							type: "area",
							name: this.chartData.series[1].name + " ",
							description: "Represents climbs that are primarly overhanging",
							color: "#71e877",
							fillOpacity: 0.5,
							data: this.chartData.series[1].data
						}
					]
				};
			}
		}
	}
};
</script>
