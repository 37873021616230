import Api from "./api";
const CODE = 'code=20MIkTgwiEL2QF2uGAddOShH8nLtdWFpivSyNYau/bHVA7NWsumNFQ==';

export default {
    //May move to its own file at some point but for now its in team
    getTeams(){
        return Api.get(`GetTeams?${CODE}`);
    },
    setTeams(){
        return Api.post(`SetTeams?${CODE}`);
    },
    getTeam(teamName) {
        return Api.get(`GetTeam?TeamName=${teamName}&${CODE}`);
    },
    setTeam(team) {
        //Figure out why im using team.team. Should be easy but TODO
        return Api.post(`SetTeam?TeamName=${team.team.fileName}&${CODE}`,team.team);
    }

    //const response = await axios.get('https://blocyardapi.azurewebsites.net/api/GetTeam?TeamName=' + teamName + '&code=EauwibjTk8mNOh6CPuTHcAl/ur2hHIpfbG12n/5z8gwFCBfDecZQDQ==')

}