<template>
  <div class="home-view-container">
    <h1>Coach's Analyser</h1>
    <!--<div class='athTable-cont'>
      <AthleteTable v-if="team.Athletes.length" :dataItems="team.Athletes" :teamName="team.TeamName" />
    </div>-->
    <div class='teamsTable-cont'>
      <b-spinner v-if="!teams.length" label="Loading..."></b-spinner>
      <TeamTable v-if="teams.length" :dataItems="teams" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TeamTable from '@/components/teamTable.vue';

export default {
  name: "Home",
  components: {
    TeamTable
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(["teams"]),
  },
  created(){
      this.getTeams(); //Make variable in the future
      this.defaultRoute();
  },
  methods: {
    ...mapActions(["getTeams"]),
    ...mapActions(["defaultRoute"])
  }
};
</script>
