export default [
    {
        id: 1,
        type: 'Wall',
        xAxis: {
            tickmarkPlacement: "on",
            categories: [
                "45°",
                "35°",
                "25°",
                "Cave",
                "Verts",
                "Mini Cave",
                "Slab",
                "3 Panel",
                "Columns"
            ]
        },
        yAxis: {
            min: 0,
            max: 10,
            labels: {
                format: "v{value}",
                align: "center"
            }
        },
        dataOptions: [[
            { value: 0, text: "v0" },
            { value: 1, text: "v1" },
            { value: 2, text: "v2" },
            { value: 3, text: "v3" },
            { value: 4, text: "v4" },
            { value: 5, text: "v5" },
            { value: 6, text: "v6" },
            { value: 7, text: "v7" },
            { value: 8, text: "v8" },
            { value: 9, text: "v9" },
            { value: 10, text: "v10+" }
        ]]
    }, {
        id: 2,
        type: 'Holds',
        xAxis: {
            tickmarkPlacement: "on",
            categories: [
                "In-Cuts",
                "Crimps",
                "Sloppers",
                "Pinches"
            ]
        },
        yAxis: {
            min: 0,
            max: 10,
            labels: {
                format: "v{value}",
                align: "center"
            }
        },
        dataOptions: [[
            { value: 0, text: "v0" },
            { value: 1, text: "v1" },
            { value: 2, text: "v2" },
            { value: 3, text: "v3" },
            { value: 4, text: "v4" },
            { value: 5, text: "v5" },
            { value: 6, text: "v6" },
            { value: 7, text: "v7" },
            { value: 8, text: "v8" },
            { value: 9, text: "v9" },
            { value: 10, text: "v10+" }
        ]]
    }, {
        id: 3,
        type: 'HangBoard',
        subType: 'Three Finger Drag',
        weightColors: ['#E5CCFF', '#CC99FF', '#B266FF', '#9933FF', '#8000FF', '#6600CC', '#4C0099', '#330066', '#190033'],
        xAxis: {
        },
        yAxis: [{
            title: {
                text: 'Hang Time',
            },
            labels: {
                format: '{value} secs',
            }
        }],
        data: {
            dateFormat: 'mm/dd/yyy',
            rows: []
        },
        dataOptions: [[
            { value: 1, text: "1 second" },
            { value: 2, text: "2 seconds" },
            { value: 3, text: "3 seconds" },
            { value: 4, text: "4 seconds" },
            { value: 5, text: "5 seconds" },
            { value: 6, text: "6 seconds" },
            { value: 7, text: "7 seconds" },
            { value: 8, text: "8 seconds" },
            { value: 9, text: "9 seconds" },
            { value: 10, text: "10 seconds" }
        ], [
            { value: -20, text: "-20 lbs" },
            { value: -15, text: "-15 lbs" },
            { value: -10, text: "-10 lbs" },
            { value: -5, text: "-5 lbs" },
            { value: 0, text: "0 lbs" },
            { value: 5, text: "5 lbs" },
            { value: 10, text: "10 lbs" },
            { value: 15, text: "15 lbs" },
            { value: 20, text: "20 lbs" },
        ]]
    }, {
        id: 4,
        type: 'HangBoard',
        subType: 'Four Finger Open Hand',
        xAxis: {
        },
        yAxis: [{
            title: {
                text: 'Hang Time',
            },
            labels: {
                format: '{value} secs',
            }
        }],
        data: {
            dateFormat: 'mm/dd/yyyy',
            rows: []
        },
        dataOptions: [[
            { value: 1, text: "1 second" },
            { value: 2, text: "2 seconds" },
            { value: 3, text: "3 seconds" },
            { value: 4, text: "4 seconds" },
            { value: 5, text: "5 seconds" },
            { value: 6, text: "6 seconds" },
            { value: 7, text: "7 seconds" },
            { value: 8, text: "8 seconds" },
            { value: 9, text: "9 seconds" },
            { value: 10, text: "10 seconds" }
        ], [
            { value: -20, text: "-20 lbs" },
            { value: -15, text: "-15 lbs" },
            { value: -10, text: "-10 lbs" },
            { value: -5, text: "-5 lbs" },
            { value: 0, text: "0 lbs" },
            { value: 5, text: "5 lbs" },
            { value: 10, text: "10 lbs" },
            { value: 15, text: "15 lbs" },
            { value: 20, text: "20 lbs" },
        ]]
    }, {
        id: 5,
        type: 'HangBoard',
        subType: 'Crimp',
        xAxis: {
        },
        yAxis: [{
            title: {
                text: 'Hang Time',
            },
            labels: {
                format: '{value} secs',
            }
        }],
        data: {
            dateFormat: 'mm/dd/yyy',
            rows: []
        },
        dataOptions: [[
            { value: 1, text: "1 second" },
            { value: 2, text: "2 seconds" },
            { value: 3, text: "3 seconds" },
            { value: 4, text: "4 seconds" },
            { value: 5, text: "5 seconds" },
            { value: 6, text: "6 seconds" },
            { value: 7, text: "7 seconds" },
            { value: 8, text: "8 seconds" },
            { value: 9, text: "9 seconds" },
            { value: 10, text: "10 seconds" }
        ], [
            { value: -20, text: "-20 lbs" },
            { value: -15, text: "-15 lbs" },
            { value: -10, text: "-10 lbs" },
            { value: -5, text: "-5 lbs" },
            { value: 0, text: "0 lbs" },
            { value: 5, text: "5 lbs" },
            { value: 10, text: "10 lbs" },
            { value: 15, text: "15 lbs" },
            { value: 20, text: "20 lbs" },
        ]]
    },
	{
        id: 6,
        type: 'Progress',
        xAxis: {
            tickmarkPlacement: "on",
            categories: [
                "Strength",
                "Fingers",
				"Flexibility",
                "Technique",
                "Footwork",
                "Mental"
            ]
        },
        yAxis: {
            min: 1,
            max: 10,
            labels: {
                format: "{value}",
                align: "center"
            }
        },
        dataOptions: [[
            { value: 1, text: "1" },
            { value: 2, text: "2" },
            { value: 3, text: "3" },
            { value: 4, text: "4" },
            { value: 5, text: "5" },
			{ value: 6, text: "6" },
			{ value: 7, text: "7" },
			{ value: 8, text: "8" },
			{ value: 9, text: "9" },
			{ value: 10, text: "10" },
        ]]
    }
]
