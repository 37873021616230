export const athleteUtil = {
    methods: {
        setAgeData(athletes) {
            athletes.forEach(athlete => {
                athlete.age = getAge(athlete.dob);
                athlete.competitionGroup = getCompGroup(athlete.dob);
            });
            return athletes;
        },
        getAge(dateString) {
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },
        getCompGroup(dateString) {
            var today = new Date();
            var todayMonth = today.getMonth();
            var todayYear = today.getFullYear();
            var birthDate = new Date(dateString);
            //We use July here because its the end of the regular season for all diciplines
            console.log("Todays Months: "+todayMonth);
            if (todayMonth > 8) {
                todayYear + 1; //New season starts in August
            }
            var compAge = todayYear - birthDate.getFullYear()
            switch (true) {
                case compAge <= 11:
                    return "D"
                case (compAge == 12 || compAge == 13):
                    return "C"
                case (compAge == 14 || compAge == 15):
                    return "B"
                case (compAge == 16 || compAge == 17):
                    return "A"
                case (compAge == 18 || compAge == 19):
                    return "JR"
                case compAge >= 20:
                    return "Open";
            }
        }
    }
}
