import Api from "./api";
const CODE = 'code=20MIkTgwiEL2QF2uGAddOShH8nLtdWFpivSyNYau/bHVA7NWsumNFQ==';

export default {
    getAthlete(id,teamName) {
        return Api.get(`GetAthlete?AthleteID=${id}&TeamName=${teamName}&${CODE}`);
    },
    setAthlete(athlete,teamName) {
        return Api.post(`SetAthlete?TeamName=${teamName}&AthleteID=${athlete.id}&${CODE}`,athlete);
    }

}