<!--
List of HighChart Session Data.
Takes in a JSON array of charts and loops over them after they have been sorted
TODO Add dynamic chart types to be passed in

-->

<template>
  <div class="chart-container" :class="[addWidth ? 'chart-container-width' : '']">
    <div v-for="session in sortedSessions" :key="session.sessionID" class="chart-template">
      <Chart
        :chartData="session"
        :sessionType="sessionType"
        :athlete="athlete"
        v-on:deleteSession="deleteSession"
      />
    </div>
    <div v-show="sortedSessions.length==0">No {{sessionType}} sessions to show</div>
  </div>
</template>

<script>
import Chart from "@/components/sessionChart.vue";
import { mapState, mapActions } from "vuex";
import "../assets/sass/components/_charts.scss";
import { BIcon, BIconXSquare } from "bootstrap-vue";

export default {
  data() {
    //This is how you limit what fields are shown
    //return{fields:[{key:'name',label: 'Full Name'},'age']}
    return {
      addWidth: false,
      showDeleteForm: false,
      typeSessions: Array
    };
  },
  props: {
    //TODO we want to change this from props to state
    sessions: Array,
    sessionType: String,
    athlete: Object
  },
  components: {
    Chart
  },
  created() {
    //Lets remove the deleted charts from the array
  },
  computed: {
    //Sort sessions, newest first
    sortedSessions: function() {
      this.typeSessions = this.getActiveSessions();
      this.typeSessions.sort((a, b) => {
        return new Date(b.startDate) - new Date(a.startDate);
      });
      return this.typeSessions;
    }
  },
  mounted() {
    //Figure out if we need to add width to help the scroling work in the CSS
    var windowWidth =
      window.screen.width < window.innerWidth
        ? window.screen.width
        : window.innerWidth;
    // ^ Because of mobile difference in width width
    if (windowWidth < 360 * this.sessions.length) {
      this.addWidth = true;
    }
  },
  methods: {
    ...mapActions(["setAthlete", "updateSessions"]),
    getActiveSessions() {
      var newSessions = [];
      this.sessions.forEach(session => {
        if (!session.deleted) {
          newSessions.push(session);
        }
      });
      return newSessions;
    },
    deleteSession(sessionID) {
      this.typeSessions.forEach(session => {
        if (session.sessionID == sessionID) {
          session.deleted = true;
        }
      });
      //Now call the API
      var success = this.updateSessions({
        sessions: this.typeSessions,
        sessionType: this.sessionType
      });
      if (success) {
        //Now call API and do a full set
        var success2 = this.setAthlete({
          teamName: this.athlete.teamFileName,
          athlete: this.athlete
        }); //Calls action
        if (success2) {
          this.$parent.$emit("deleteSession"); //Emit event to Parent to re-render the data this component is using
          this.$root.$emit("bv::hide::modal", "modalDeleteSession");
        }
      }
    }
  }
};
</script>
<style lang="scss">
.chart-container {
  display: inline-flex;
  overflow: auto;
}
.chart-container-width {
  width: 100%;
}
.chart-template {
  width: 360px;
  height: 484px;
}
</style>
