<template>
  <div v-if="practice.id">
    <h1>
      Practice
      <!--<b-icon-pencil
        class="b-icon editPageIcon"
        @click="editPractice()"
      ></b-icon-pencil>-->
    </h1>
    <h3 class="subTitle">{{ this.formatDate(practice.date) }}</h3>
    <b-modal
      v-model="showEditPractice"
      id="modalAddPractice"
      ref="modal"
      title="Add Practice"
      :hideFooter="true"
      @hidden="handleOk"
    >
      <AddPractice :practice="practice" />
    </b-modal>
    <hr />
    <br />
    <div>
      <b-spinner v-show="loading" label="Loading..."></b-spinner>
    </div>
    <div class="row">
      <div class="col-sm">
        <PracticeChart
          v-if="practice.id == this.$route.params.id"
          :scale="'single'"
          :chartTitle="'Targets'"
        />
      </div>
      <div class="col-sm">
        <h2>Notes:</h2>
        {{ practice.notes }}
        <br />
        <br />
        <DrillTable
          v-if="practice.drills.length"
          :dataItems="practice.drills"
          :teamName="this.$route.params.teamName"
          :fields="fields"
        />
      </div>
    </div>
  </div>
</template>


<script type="text/javascript">
import { mapState, mapActions, mapGetters } from "vuex";
import { globalUtil } from "../mixins/globalUtil.js";
import DrillTable from "@/components/drillTable.vue";
import PracticeChart from "@/components/practiceChart.vue";
import AddPractice from "@/components/addPractice.vue";

export default {
  mixins: [globalUtil],
  data() {
    return {
      loading: true,
      showEditPractice: false,
      fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        },
        {
          key: "targets",
          label: "Targets",
        },
        {
          key: "difficultyRating",
          label: "Difficulty Rating",
          sortable: true,
        },
        {
          key: "kidFriendly",
          label: "Kid Friendly",
          sortable: true,
        },
      ],
    };
  },
  components: {
    DrillTable,
    PracticeChart,
    AddPractice,
  },
  computed: {
    ...mapState(["team"]),
    ...mapState(["practice"]),
  },
  created() {
    this.getPractice({
      teamName: this.$route.params.teamName,
      practiceID: this.$route.params.id,
    }).then((value) => {
      this.updateRouting({
        depth: 4,
        path: this.$route.path,
        name: "Practice",
      });
      this.loading = false;
    }); //Make variable in the future
  },
  methods: {
    ...mapActions(["getPractice"]),
    ...mapActions(["updateRouting"]),
    formatDate(value) {
      return this.getFormattedDate(new Date(value));
    },
    editPractice() {
      this.showEditPractice = true;
    },
    handleOk(bvModalEvt) {
      this.getPractice({
        teamName: this.$route.params.teamName,
        practiceID: this.$route.params.id,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.charts-cont {
  padding: 6px 0px;
}
</style>