<template>
  <div>
    <div class='teamsTable-cont'>
      <b-spinner v-if="!teams.length" label="Loading..."></b-spinner>
      <TeamTable v-if="teams.length" :dataItems="teams" />
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters, mapState } from "vuex";
import TeamTable from "@/components/teamTable.vue";

export default {
  name: "Teams",
  components: {
    TeamTable
  },
  data() {
    return {
      
    };
  },
  computed: {
    ...mapState(["teams"]),
  },
  created(){
      this.getTeams(); //Make variable in the future
      this.defaultRoute();
  },
  methods: {
    ...mapActions(["getTeams"]),
    ...mapActions(["defaultRoute"])
  }
};
</script>
