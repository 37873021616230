<!--
There is a fundamental.. issue with how we are populating sessionData.
Its realing on the order of the categories never changing.
-->
<template>
  <div>
    <b-form class="form-cont">
      <b-container class="form-template" fluid="sm">
        <b-row
          v-for="(category,index) in selectedType.xAxis.categories"
          :key="category"
          style="padding-top:8px;padding-bottom:8px;"
        >
          <b-col class="category-col">{{category}}:</b-col>
          <b-col>
            <b-form-select v-model="sessionData[index]" :options="gradeOptions" fluid="sm"></b-form-select>
          </b-col>
        </b-row>
      </b-container>
    </b-form>

    <b-alert v-model="showIncompleteAlert" variant="danger" dismissible>Form is incomplete!</b-alert>
    <!--TODO Disable btns until they can actually do something, also hide them until a type is selected -->
    <b-button variant="success" @click="finalizeSession(selectedType.type)">Finalize</b-button>
    <b-button variant="purple" @click="saveSession(selectedType.type,null)">Save</b-button>
    <b-button variant="danger" style="float:left;">Reset</b-button>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { sessionUtil } from "../mixins/sessionUtil.js";

export default {
  mixins: [sessionUtil],
  props: {
    chartData: Object,
    sessionType: String,
    athlete: Object
  },
  data() {
    return {
      selectedType: {},
      showIncompleteAlert: false,
      gradeOptions: [
        { value: 0, text: "v0" },
        { value: 1, text: "v1" },
        { value: 2, text: "v2" },
        { value: 3, text: "v3" },
        { value: 4, text: "v4" },
        { value: 5, text: "v5" },
        { value: 6, text: "v6" },
        { value: 7, text: "v7" },
        { value: 8, text: "v8" },
        { value: 9, text: "v9" },
        { value: 10, text: "v10+" }
      ],
      sessionData: []
    };
  },
  computed: {
    ...mapState(["sessionTypes"])
  },
  beforeMount() {
    //In here because we need selectedType in the HTML above
    this.sessionTypes.forEach(sessionTypeObj => {
      if (
        sessionTypeObj.type.toLowerCase() === this.sessionType.toLowerCase()
      ) {
        this.selectedType = sessionTypeObj;
      }
    });
  },
  mounted() {
    this.chartData.series.forEach(series => {
      if (series.name == "This Session") {
        this.sessionData = series.data;
      }
    });
  },
  methods: {
    ...mapActions(["updateSession", "setAthlete"]),
    clearFormData() {
      //resent form after submit
      return {
        //TODO
      };
    },
    saveSession(type, finishDate) {
      var newestSessionData = this.cleanSessionData(this.sessionData);
      const payload = {
        athleteID: this.athlete.id,
        chartData: this.chartData,
        type: type,
        finishDate: finishDate,
        series: [
          {
            name: "Season Average",
            data: this.calcSeasonAverage(type,newestSessionData)
          },
          {
            name: "This Session",
            data: newestSessionData
          }
        ]
      };

      var success = this.updateSession(payload); //Uses the Store

      if (success) {
        var success2 = this.setAthlete({
          teamName: this.athlete.teamFileName,
          athlete: this.athlete
        }); //Calls action
        if (success2) {
          this.$root.$emit("bv::hide::modal", "modalResumeSession");
        }
      }
    }
  }
};
</script>