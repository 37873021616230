import axios from 'axios';

const Email = axios.create({
	baseURL: 'https://api.emailjs.com/api/v1.0/email/', // Prod URL
	//baseURL:'http://localhost:7071/api', //Test URL
	crossDomain: true,
	//Access-Control-Allow-Origin
	/*params: {
		service_id: 'service_1n7txz8',
		user_id: 'user_Vv53TqZmYep63qwh8dKrW',
		template_id: 'template_57d4xxf',
	}*/
});

export default Email;