<template>
	<b-container class="information" fluid="md">
		<h2 style="text-align:center; padding-bottom:24px;">Competitive Team</h2>
		<h3>Summary</h3>
		<p>
			The competitive team places a high focus on developing the athlete to compete on the USA Climbing Youth
			Circuit.
			This includes a much more designed approach when it comes to training the athlete for competition.
			Things like youth appropriate Strength Training, Advanced technique practice, Mobility work through Yoga and
			Sports Psychology practices to help them compete at their highest level.

		</p>

		<h3>Team includes:</h3>
		<ul>
			<li>2-4 coaches for every practice</li>
			<li>Team jersey</li>
			<li>Training Journal</li>
			<li>Professionally designed training program</li>
			<li>3 practices a week (Monday, Tuesday, Thursday)</li>
		</ul>

		<h3>Requirements:</h3>
		<ul>
			<li>Must be at least 10 years old by end of calendar year <b>OR</b> has been on the team previously</li>
			<li>Must compete in 2 Local competitions and Regionals (if qualified)</li>
		</ul>

		<div v-if="!offseason">
			<h3>When is it?:</h3>
			<div class="l2">
				<h4>Open House:</h4>
				<p>
					Curious what the team is about? Not sure which team you should sign your child up for? Come to the Teams Open House! 
					It will be a great chance to meet some of the coaches and learn more about the team! 
					<b>Join us August 24th 10am - 12pm at the Bloc Yard Gym</b>
					<br />
				</p>
				<h4>Practices Start:</h4>
				<p>
					The first day of practice for the 2024 - 2025 season will be <b>September 3rd (Subject to change) for BOTH teams from 4pm -
						6pm</b><br /><br />
				</p>
			</div>

			<h3>How much is it?:</h3>
			<span style="color:#8000ff;"><b>NOTE:</b> A membership AND coaching fee are required to be on the
				team</span>
			<ul>
				<li>Monthly EFT memberships (Sept-Feb) - $54/month <b>OR</b> Prepaid Annual - $399 for team members. <b>Thats $100 savings for the year.</b></li>
				<li>Coaching fee - $85/month (required)</li>
			</ul>

		</div>
	</b-container>
</template>
<script>
import "../assets/sass/_contact.scss";

export default {
	data() {
		return {
			showSignUp: false,
			offseason: true,
		};
	},
	computed: {},
};
</script>
