import Api from "./api";
const CODE = 'code=20MIkTgwiEL2QF2uGAddOShH8nLtdWFpivSyNYau/bHVA7NWsumNFQ==';

export default {
    getGlobalTargets() {
        return Api.get(`GetTargets?${CODE}`);
    },
    getDrills() {
        return Api.get(`GetDrills?${CODE}`);
    },
    setDrills(drills){
        return Api.post(`SetDrills?${CODE}`,{drills:drills})
    },
    getDrillTypes() {
        return Api.get(`GetDrillTypes?${CODE}`);
    },
    setMedia(file,teamName,fileName){
        return Api.post(`SetMedia?TeamName=${teamName}&FileName=${fileName}&${CODE}`,file)
    }
}