<!--
There is a fundamental.. issue with how we are populating sessionData.
Its relying on the order of the categories never changing.
-->
<template>
  <b-form>
    <b-container class="form-template" fluid="sm">
      <b-form-select v-model="selectedType" :options="types"></b-form-select>
    </b-container>

    <hr />

    <transition name="fade">
      <div v-if="Object.keys(selectedType).length !== 0">
        <b-form class="form-cont">
          <b-container class="form-template" fluid="sm">
            <b-row
              v-for="(category,index) in selectedType.xAxis.categories"
              :key="category"
              style="padding-top:8px;padding-bottom:8px;"
            >
              <b-col class="category-col">{{category}}:</b-col>
              <b-col>
                <b-form-select
                  v-model="sessionData[index]"
                  :options="selectedType.dataOptions[0]"
                  fluid="sm"
                ></b-form-select>
              </b-col>
              <b-col v-if="selectedType.dataOptions.length >=2">
                <b-form-select :options="selectedType.dataOptions[1]" fluid="sm"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-icon-plus-square
                  v-if="selectedType.type =='HangBoard'"
                  class="clickable addIcon"
                  variant="secondary"
                  @click="addRep()"
                ></b-icon-plus-square>
              </b-col>
            </b-row>
          </b-container>
        </b-form>
      </div>
    </transition>
    <!--TODO Disable btns until they can actually do something, also hide them until a type is selected -->
    <b-button
      v-if="Object.keys(selectedType).length !== 0"
      variant="success"
      @click="saveSession(selectedType.type, getFormattedDate(new Date()))"
    >Save</b-button>
    <!--<b-button
      v-if="Object.keys(selectedType).length !== 0"
      variant="success"
      @click="finalizeWallSession(selectedType.type)"
    >Finalize</b-button>-->
    <b-button v-if="Object.keys(selectedType).length !== 0" variant="danger">Reset</b-button>
  </b-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import "../assets/sass/components/_newSession.scss";
import { sessionUtil } from "../mixins/sessionUtil.js";
import { globalUtil } from "../mixins/globalUtil.js";

export default {
  mixins: [sessionUtil,globalUtil],
  props: {
    athlete: Object
  },
  data() {
    return {
      selectedType: {},
      types: [{ value: {}, text: "Select Type of Session" }],
      sessionData: []
    };
  },
  computed: {
    ...mapState(["sessionTypes"])
  },
  mounted() {
    this.sessionTypes.forEach(sessionType => {
      this.types.push({
        value: sessionType,
        text:
          sessionType.type +
          (sessionType.subType ? " - " + sessionType.subType : "")
      });
    });
  },
  methods: {
    ...mapActions(["addSession", "setAthlete"]),
    clearFormData() {
      //resent form after submit
      return {
        //TODO
      };
    },
    addRep() {
      var length = this.selectedType.xAxis.categories.length;
      this.selectedType.xAxis.categories.push("Rep " + (length + 1));
    },
    saveSession(type, finishDate) {
      var newestSessionData = this.cleanSessionData(this.sessionData);
	  console.log("newestSessionData: "+newestSessionData );
      const payload = {
        type: type,
        session: {
          sessionID: this.getSessionID(type),
          startDate: this.getFormattedDate(new Date()),
          finishDate: finishDate,
          series: [
            {
              name: "First Session",
              data: this.getFirstSession(type,newestSessionData)
            },
            {
              name: "This Session",
              data: newestSessionData
            }
          ]
        }
      };
      //BUG here, we are calcSeasonAverage before the newest session has been created
      this.addSession(payload); //Uses the Store

      var success = this.setAthlete({
        teamName: this.athlete.teamFileName,
        athlete: this.athlete
      }); //Calls action
      if (success) {
        this.clearFormData();

        //Now we are emiting events to the parent.
        //Open Type Component that the new session was created to:
        this.$emit("toggleShowType", this.selectedType.type);
        //Will close this component
        this.$emit("closeSession");
      }
    }
  }
};
</script>
