<template>
	<b-container class="information" fluid="md">
		<h2 style="text-align:center; padding-bottom:24px;">Development Team</h2>
		<h3>Summary</h3>
		<p>
			The development team takes a fun first approach that focuses on learning proper movement while climbing and overall
			technique.
			We keep it light and fun with the overarching goal of developing a love for climbing.
			Beyond the games and technique building we will focus a little bit on developing climbing specific strength through
			‘on the wall’ style drills.
		</p>

		<h3>Team includes:</h3>
		<ul>
			<li>A coach for every practice</li>
			<li>2 practices a week (Tuesday and Friday)</li>

		</ul>

		<h3>Requirements:</h3>
		<ul>
			<li>Must be at least 8 years old by end of calendar year</li>
		</ul>

		<div v-if="!offseason">
			<h3>When is it?:</h3>
			<div class="l2">
				<h4>Open House:</h4>
				<p>
					Curious what the team is about? Not sure which team you should sign your child up for? Come to the Teams Open House! 
					It will be a great chance to meet some of the coaches and learn more about the team! 
					<b>Join us August 24th 10am - 12pm at the Bloc Yard Gym</b>
					<br />
				</p>
				<h4>Practices Start:</h4>
				<p>
					The first day of practice for the 2024 - 2025 season will be <b>September 3rd (Subject to change) for BOTH teams from 4pm -
						6pm</b><br /><br />
				</p>
			</div>

			<h3>How much is it?:</h3>

			<span style="color:#8000ff;"><b>NOTE:</b> A membership AND coaching fee are required to be on the
				team</span>
			<ul>
				<li>Monthly EFT memberships (Sept-Feb) - $54/month <b>OR</b> Prepaid Annual - $399 for team members. <b>Thats $100 savings for the year.</b></li>
				<li>Coaching fee - $45/month (required)</li>
			</ul>
			<!--
			<h4 style="color: #aaa; font-size: 1.25em">Gear</h4>
			We also offer branded team gear at lower then retail cost with only
			the Jersey being mandetory.
			<ul>
				<li>Team Jersey- $35</li>
				<li>Organic roll down pack- $53.40 (optional)</li>
				<li>Organic chalk bucket- $21.00 (optional)</li>
				<li>Mad Rock Lycan brush- $5.00 (optional)</li>
			</ul>
			-->
		</div>
	</b-container>
</template>
<script>
import "../assets/sass/_contact.scss";

export default {
	data() {
		return {
			showSignUp: false,
			offseason: true,
		};
	},
	computed: {},
};
</script>
