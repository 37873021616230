//import athletes from '../data/athletes'
import sessionTypes from '../data/session' //TODO add this to blob storage

export default {
	teams: {},
	team: {},
	athlete: {},
	sessionTypes,
	practice: {},
	routes: [{ path: "/", name: "Home" }, { path: "/Teams", name: "Teams" }, { path: "/About", name: "About" }],
	targets: [],
	drills: {},
	drillTypes: {}
}
