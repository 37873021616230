<template>
	<b-container class="information" fluid="md">
		<img src="../assets/media/team23-24_crop.jpg" style="width:100%">
		<br /><br />
		<h3>Summary</h3>
		<p>
			The Bloc Yard Youth Teams are designed to provide a full range of opportunities to learn about climbing,
			compete in climbing and most importantly have fun climbing.
			To help service the needs of all the kids we have split the Youth Team into two different teams. A <b>‘Competitive
				Team’</b> and a <b>‘Development Team’</b>.
			<br /><br />
			If at anytime during the season your athlete does not feel like the team they are on fits them, they can
			change teams.
			<br /><br />
			<span style="color:#8000ff;"><b>NOTE:</b> Each team has its own focus and goals, which can be read about by
				clicking the links above.</span>

		</p>
		<h3>When is it?:</h3>
		<div class="l2">
			<h4>Open House:</h4>
			<p>
				Curious what the team is about? Not sure which team you should sign your child up for? Come to the Teams Open House! 
				It will be a great chance to meet some of the coaches and learn more about the team! 
				<br /><br />
				<b>Join us August 24th 10am - 12pm at the Bloc Yard Gym</b>
				<br />
			</p>
			<h4>Practices Start:</h4>
			<p>
				The first day of practice for the 2024 - 2025 season will be <b>September 3rd (Subject to change) for BOTH teams from 4pm -
					6pm</b><br /><br />
			</p>
		</div>
		<h3>Who is it?:</h3>
		<div class="l2">
			<h4 style="color: #aaa; font-size: 1.25em">Benjamin Aguilar</h4>
			<p>
				I have been the Head Coach at the Bloc Yard for the last 7 years and
				have enjoyed every moment of it. For me its a chance to give back to
				the community that has given so much to me. My goal is to help develop your
				kids into great athletes and more importantly great people. I am a
				certified USA Climbing coach and a certified sports pyschologist who has been avidly climbing since
				2011.
			</p>
		</div>
		<div class="l2">
			<h4 style="color: #aaa; font-size: 1.25em">George Hughbanks</h4>
			<p>
				I began my climbing life in 1992 right here in Spokane.
				I cut my teeth on the crags of the west coast of America learning the arts of traditional climbing.
				I was fortunate to also come into climbing at the explosion of sport climbing and soon gym climbing.
				I helped build Wild Walls and had the opportunity to begin my route setting career.
				I have coached climbing for Wild Walls and the Spokane Climbing Association in their early years.
				I have been the head setter for over 50 competitions encompassing local, regional, and collegiate
				events.
				I have a unique perspective as a coach and route setter that I am excited to share.
				When I am not busy supporting my kids in their passions mine is searching for new boulders to climb and
				new areas to develop for climbing. And when I can pack it into life I am on the board for the Bower
				Climbing Coalition, we are a non profit organization with the goal of replacing bad and aging anchors,
				improving climbing area trails and belay stances for our local climbing areas. I encourage you to join.
			</p>
		</div>
		<div class="l2">
			<h4 style="color: #aaa; font-size: 1.25em">Spencer Gage</h4>
			<p>
				I’m Spencer Gage and am a 21 year old graduate from Lewis and Clark High School.
				I’m attending EWU this year in pursuit of an elementary education degree.
				I started climbing back in 2018 and fell in love with the sport.
				The combination of pushing yourself physically and analyzing the climbs to ascend them efficiently was
				very compelling to me and climbing became a big part of my life.
				I have taught Sunday School for the past five years, and am very experienced in leading youth and
				creating a positive environment where both the kids and I have a great time.
				I can’t wait to hang out with your kids as we climb hard and have fun together!
			</p>
		</div>
		<div class="l2">
			<h4 style="color: #aaa; font-size: 1.25em">Chloe Trotz</h4>
			<p>
				This is Chloe, I am 21. I have learned how to climb with my aunt Heather since I was in 4th grade or younger. 
				We did indoor rock climbing, however most of my time was spent climbing outside growing up. 
				I have graduated from Mead high school and ever since. 
				I’ve been very passionate about coaching for kids and helping them learn new skills. 
				I have also worked as a gymnastics coach and am looking forward to working with young rock climbers! 
			</p>
		</div>
		<div class="l2">
			<h4 style="color: #aaa; font-size: 1.25em">Bri Skiles</h4>
			<p>
				I completed my bachelor’s degree in Sociology from Gonzaga University in 2017, and have since spent my
				time working with a diverse array of youth and young adults as a mentor, tutor, and certified Peer
				Counselor.
				I started climbing in 2018, and Bloc Yard has been my main source of community, growth, and connection—
				both as a climber and a human.
				Becoming a coach at Bloc Yard allows me to get more involved with this incredible community, and to give
				back.
				As a coach, my goal is to utilize a holistic lens to foster growth, resilience, and strength within
				every athlete.
			</p>
		</div>
		<div class="l2">
			<h4 style="color: #aaa; font-size: 1.25em">Evelyn Harris</h4>
			<p>
				My name is Evelyn Harris, I'm 19 years old. I've been climbing my whole life, but it wasn't until I joined Bloc Yard that I became fully hooked. 
				Since then, I've been climbing consistently and eventually joined the Bloc Yard crew. I help with route setting and coaching, and I'm thrilled to be part of this team. 
				Climbing and the community have been a positive and encouraging environment for me, inspiring me to coach and motivate kids. 
				I want to encourage them to try their hardest while having fun. 
				I'm excited to meet all the energetic kids eager to climb in the coming year! 
			</p>
		</div>
		<div class="l2">
			<h4 style="color: #aaa; font-size: 1.25em">Nate Batters</h4>
			<p>
				I go by Nate. I’m 30 years old and I’ve been climbing 9 years. I’m also the Head Setter at Blocyard. 
				My goal is to create a stronger climbing community here in Spokane and I believe that starts with the youth.
			</p>
		</div>
	</b-container>
</template>
<script>
import "../assets/sass/_contact.scss";

export default {
	data() {
		return {
			showSignUp: false,
			offseason: true,
		};
	},
	computed: {},
};
</script>
