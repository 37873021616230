<template>
  <div id="app">
    <!--<div id="nav">
      <span v-for="route in routes" :key="route.path">
        <router-link :to="`${route.path}`">{{route.name}} </router-link>|
      </span>
    </div>-->
    <b-container>
      <router-view />
    </b-container>
  </div>
</template>
<script type="text/javascript">
import { mapActions, mapGetters, mapState } from "vuex";
import { SpinnerPlugin } from "bootstrap-vue";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["routes"]),
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
