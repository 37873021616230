<template>
  <div>
    <!--TODO, move the add logic into the table? if possible-->
    <b-spinner v-if="!team.practices.length" label="Loading..."></b-spinner>
    <button
      :class="[showAddPractice ? 'btn-secondary' : 'btn-purple']"
      @click="addPractice()"
      class="btn"
    >Add Practice</button>
    <b-modal
      v-model="showAddPractice"
      id="modalAddPractice"
      ref="modal"
      title="Add Practice"
      :hideFooter="true"
      @hidden="handleOk"
    >
      <AddPractice />
    </b-modal>

    <button
      :class="[showAddDrill? 'btn-secondary' : 'btn-purple']"
      @click="addDrill()"
      class="btn"
    >Add Drill</button>
    <b-modal
      v-model="showAddDrill"
      id="modalAddDrill"
      ref="modal"
      title="Add Drill"
      :hideFooter="true"
      @hidden="handleOk"
    >
      <AddDrill />
    </b-modal>
    <PracticeTable
      v-if="team.practices.length"
      :dataItems="team.practices"
      :teamName="this.$route.params.teamName"
    />
  </div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters, mapState } from "vuex";
import PracticeTable from "@/components/practiceTable.vue";
import AddPractice from "@/components/addPractice.vue";
import AddDrill from "@/components/addDrill.vue";

export default {
  name: "Practices",
  components: {
    PracticeTable,
    AddPractice,
    AddDrill,
  },
  data() {
    return {
      showAddPractice: false,
      showAddDrill: false,
    };
  },
  computed: {
    ...mapState(["team", "teams", "drills", "drillTypes", "targets"]),
  },
  created() {
    this.getDrills(this.$route.params.teamName).then((value) => {
      this.getDrillTypes();
    });
    this.getGlobalTargets();
  },
  methods: {
    ...mapActions(["getDrills", "getDrillTypes", "getGlobalTargets"]),
    addPractice() {
      this.showAddPractice = true;
    },
    addDrill() {
      this.showAddDrill = true;
    },
    handleOk(bvModalEvt) {
      this.getDrills(this.$route.params.teamName);
    },
  },
};
</script>
