
<template class="chart-template">
  <div>
    <div>
      <b-spinner v-show="loading" label="Loading..."></b-spinner>
    </div>
    <figure class="highcharts-figure">
      <div v-bind:id="'container-'+scale+'-'+practiceID"></div>
    </figure>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import "../assets/sass/components/_chart.scss";
import { practiceUtil } from "../mixins/practiceUtil.js";

export default {
  mixins: [practiceUtil],
  data() {
    return {
      loading: true,
    };
  },
  props: {
    scale: String,
    chartTitle: String,
    practiceID: Number,
  },
  computed: {
    ...mapState(["practice", "team"]),
  },
  created() {},
  mounted() {
    if (this.practiceID <= 0) {
      this.practiceID = this.$route.params.id;
    }
    this.getPractice({
      teamName: this.$route.params.teamName,
      practiceID: this.practiceID,
    }).then((value) => {
      var obj = this.buildPieChart();
      Highcharts.chart("container-" + this.scale + "-" + this.practiceID, obj);
      this.loading=false;
    });
  },
  methods: {
    ...mapActions(["getPractice"]),
    buildTargetData(inPractice) {
      var targetList = [];
      inPractice.drills.forEach((drill) => {
        targetList.push.apply(targetList, drill.targets);
      });
      return targetList;
    },
    getTargetData() {
      var targetList = [];
      var targetListLoop = [];
      var targetData = [];

      //Get All Drill targets
      switch (this.scale) {
        case "all":
          this.team.practices.forEach((practice) => {
            targetList.push.apply(targetList, this.buildTargetData(practice));
          });
          break;
        case "partial":
          this.team.practices.forEach((practice) => {
            if (this.isFinished(practice.date)) {
              targetList.push.apply(targetList, this.buildTargetData(practice));
            }
          });
          break;
        default:
          targetList = this.buildTargetData(this.practice);
          break;
      }

      //Add only unique values to targetListLoop array
      targetListLoop.push.apply(targetListLoop, [...new Set(targetList)]);
      //Figure out percentage of All targets each is
      targetListLoop.forEach(function (mainTarget, mainIndex) {
        var count = 0;
        targetData.push({ name: mainTarget, count: count, y: 100 });
        targetList.forEach((target) => {
          if (mainTarget.toLowerCase() == target.toLowerCase()) {
            targetData[mainIndex].count++;
          }
        });
        //Get %
        targetData[mainIndex].y =
          targetData[mainIndex].count / targetListLoop.length;
      });
      return targetData;
    },
    buildPieChart() {
      return {
        chart: {
          type: "pie",
          //height: 400,
          //width: 360
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 1200,
                minWidth: 320,
              },
            },
          ],
        },
        title: {
          text: this.chartTitle,
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
        tooltip: {
          pointFormat: "{point.percentage:.1f}%",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        series: [
          {
            name: "Practice Total",
            colorByPoint: true,
            data: this.getTargetData(),
          },
        ],
      };
    },
    handleOk(bvModalEvt) {
      // Trigger submit handler
      var obj = this.buildPieChart();
      Highcharts.setOptions({});
      Highcharts.chart("container-" + this.practice.id, obj);
    },
  },
};
</script>