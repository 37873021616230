<template>
  <div>
    <h1>{{ team.teamName }}</h1>
    <h3 class="subTitle">{{ team.teamMotto }}</h3>
    <!--TODO, We want to add repeorts / graphs here on current practices and the season-->
    <div class="row">
      <div class="col-sm">
        <PracticeChart
          v-if="team.practices.length > 0"
          :scale="'partial'"
          :chartTitle="'Where we are at'"
        />
      </div>
      <div class="col-sm">
        <PracticeChart
          v-if="team.practices.length > 0"
          :scale="'all'"
          :chartTitle="'Where we are going'"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm">
        <Practices />
      </div>
      <div class="col-sm">
        <Athletes />
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters, mapState } from "vuex";
import Athletes from "@/components/athletes.vue";
import Practices from "@/components/practices.vue";
import PracticeChart from "@/components/practiceChart.vue";

export default {
  name: "Team",
  components: {
    Athletes,
    Practices,
    PracticeChart,
  },
  data() {
    return {
      showAthletes: false,
    };
  },
  computed: {
    ...mapState(["team", "teams"]),
  },
  created() {
    this.getTeam(this.$route.params.teamName).then((value) => {
      this.updateRouting({
        depth: 3,
        path: this.$route.path,
        name: this.team.teamName,
      });
    }); //Make variable in the future
  },
  methods: {
    ...mapActions(["getTeam"]),
    ...mapActions(["updateRouting"]),
    handleOk(bvModalEvt) {
      // Trigger submit handler
      //var obj = this.buildObject();
      // Highcharts.chart("container-" + this.chartData.sessionID, obj)
    },
  },
};
</script>
