import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Teams from '../views/Teams.vue'
import Team from '../views/Team.vue'
import Athlete from '../views/Athlete.vue'
import Practice from '../views/Practice.vue'
import About from '../views/About.vue'
import AboutCompTeam from '../components/aboutCompTeam.vue'
import AboutDevTeam from '../components/aboutDevTeam.vue'
import AboutTeams from '../components/aboutTeams.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/Teams',
		name: 'Teams',
		component: Teams,
		meta: {
			module: 'Teams'
		}
	},
	{
		path: '/about',
		component: About,
		children: [
			{
				path: '',
				name: 'about',
				component: AboutTeams
			},{
				path: 'comp',
				name: 'Competitive Team',
				component: AboutCompTeam
			}, {
				path: 'dev',
				name: 'Development Team',
				component: AboutDevTeam
			}
		]
	},
	{
		path: '/:teamName',
		name: 'Team',
		component: Team
	},
	{
		path: '/:teamName/Athletes/:id',
		name: 'Athlete',
		component: Athlete
	},
	{
		path: '/:teamName/Practice/:id',
		name: 'Practice',
		component: Practice
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
