<template>
  <div>
    <!--TODO, move the add logic into the table? if possible-->
    <button
      :class="[showAddAthlete ? 'btn-secondary' : 'btn-purple']"
      @click="addAthlete()"
      class="btn"
    >
      Add Athlete
    </button>
    <b-modal
      v-model="showAddAthlete"
      id="modalAddAthlete"
      ref="modal"
      title="Add Athlete"
      :hideFooter="true"
      @hidden="handleOk"
    >
      <AddAthlete :team="team" />
    </b-modal>
    <b-spinner v-if="!team.athletes.length" label="Loading..."></b-spinner>
    <AthleteTable
      v-if="team.athletes.length"
      :dataItems="team.athletes"
      :teamName="this.$route.params.teamName"
    />
  </div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters, mapState } from "vuex";
import AthleteTable from "@/components/athleteTable.vue";
import AddAthlete from "@/components/addAthlete.vue";

export default {
  name: "Athletes",
  components: {
    AthleteTable,
    AddAthlete,
  },
  data() {
    return {
      showAddAthlete: false,
    };
  },
  computed: {
    ...mapState(["team", "teams"]),
  },
  methods: {
    ...mapActions(["updateRouting"]),
    addAthlete() {
      this.showAddAthlete = true;
    },
    handleOk(bvModalEvt) {
      // Trigger submit handler
      //var obj = this.buildObject();
      // Highcharts.chart("container-" + this.chartData.sessionID, obj)
    },
  },
};
</script>
