export const sessionUtil = {
	methods: {
		calcSeasonAverage(type, newData) {
			var i = 0;
			var averages = [];
			while (this.selectedType.xAxis.categories.length > i) {
				var total = newData[i];
				var divider = 1;
				this.athlete.allSessions.forEach(sessionType => {
					if (sessionType.type == type.toLowerCase()) {
						sessionType.sessions.forEach(session => {
							if (session.finishDate != null && !session.deleted) {
								session.series.forEach(series => {
									if (series.name == 'This Session') {
										total += series.data[i];
										divider++;
									}
								});
							}
						});
					}
				});
				averages.push(total / divider);
				i++;
			}
			return averages;
		},
		cleanSessionData(sessionData) {
			//This is in place for undeifned values that happen when nothing is entered.
			var i = 0;
			while (sessionData.length > i) {
				if (typeof sessionData[i] === "undefined") {
					sessionData[i] = 0;
				}
				i++;
			}
			return sessionData;
		},
		getSessionID(type) {
			var id = 1;
			this.athlete.allSessions.forEach(sessionType => {
				if (type.toLowerCase() == sessionType.type.toLowerCase()) {
					id = sessionType.sessions.length + 1;
				}
			});
			return id;
		},
		finalizeWallSession(type) {
			if (this.sessionData.length == this.selectedType.xAxis.categories.length || this.showIncompleteAlert == true) {
				//Will want to adjust this at some point
				this.saveSession(type, this.getFormattedDate(new Date())); //Function should be in the component
			} else {
				//Display alert and do not submit
				this.showIncompleteAlert = true;
			}
		},
		//This needs to go away, dup in globalUtil
		getFormattedDate(date) {

			var dd = String(date.getDate()).padStart(2, '0');
			var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
			var yyyy = date.getFullYear();

			var today = mm + '/' + dd + '/' + yyyy;
			return today;
		},
		getFirstSession(type, newestSessionData) {
			var firstSessionData = newestSessionData;
			if (this.athlete.allSessions.length > 0) {
				//Loop over session types
				this.athlete.allSessions.forEach(sessionType => {
					if (sessionType.type.toLowerCase() == type.toLowerCase()) {
						//Loop over sessions of the right type
						sessionType.sessions.sort((a, b) => {
							return a.id - b.id;
						});
						//Loop over series of the session to get data
						sessionType.sessions[0].series.forEach(series => {
							if (series.name.toLowerCase() == "this session") {
								firstSessionData= series.data;
							}
						});
					}
				});
			}
			return firstSessionData;
		}
	}
}
