import Email from "./emailService";

export default {
	sendEmail(data) {
		var serviceData = {
			service_id: 'service_1n7txz8',
			user_id: 'user_Vv53TqZmYep63qwh8dKrW',
			template_id: 'template_57d4xxf',
			template_params: data
		}
		return Email.post(`send`, serviceData);
	}

}