
<template>
  <div>
    <b-form class="form-cont">
      <b-container class="form-template" fluid="sm">
        <b-row style="padding-top: 8px; padding-bottom: 8px">
          <b-col sm="3">
            <label for="drillName">Drill Name:</label>
          </b-col>
          <b-col>
            <b-form-input
              id="drillName"
              v-model="newDrill.name"
              placeholder="Enter Name of Drill"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row style="padding-top: 8px; padding-bottom: 8px">
          <b-col sm="3">
            <label for="drillType">Drill Type:</label>
          </b-col>
          <b-col>
            <b-form-select
              id="drillType"
              on
              v-model="newDrill.type.name"
              :options="drillTypes"
              fluid="sm"
            ></b-form-select>
          </b-col>
        </b-row>

        <!--Targets-->
        <b-row>
          <b-col>
            <b-form-group label="Choose what the Drill Targets">
              <b-form-tags
                v-model="newDrill.targets"
                no-outer-focus
                class="mb-2"
              >
                <template v-slot="{ tags, disabled, addTag, removeTag }">
                  <ul
                    v-if="tags.length > 0"
                    class="list-inline d-inline-block mb-2"
                  >
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                      <b-form-tag
                        @remove="removeTag(tag)"
                        :title="tag"
                        :disabled="disabled"
                        variant="secondary"
                        >{{ tag }}</b-form-tag
                      >
                    </li>
                  </ul>

                  <b-dropdown
                    size="sm"
                    variant="secondary"
                    block
                    menu-class="w-100"
                  >
                    <template v-slot:button-content >
                      <b-icon icon="tag-fill" variant="primary" ></b-icon>Choose Targets
                    </template>
                    <b-dropdown-item-button
                      v-for="option in targets"
                      :key="option"
                      @click="onOptionClick({ option, addTag })"
                      >{{ option }}</b-dropdown-item-button
                    >
                    <b-dropdown-text v-if="targets.length === 0"
                      >There are no targets available to select</b-dropdown-text
                    >
                  </b-dropdown>
                </template>
              </b-form-tags>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row style="padding-top: 8px; padding-bottom: 8px">
          <b-col sm="3">
            <label for="notes">Description:</label>
          </b-col>
          <b-col>
            <b-form-textarea
              id="notes"
              v-model="newDrill.description"
              placeholder="Describe the Drill"
              rows="3"
              max-rows="6"
            />
          </b-col>
        </b-row>

        <b-row style="padding-top: 8px; padding-bottom: 8px">
          <b-col sm="3">
            <label for="notes">How:</label>
          </b-col>
          <b-col>
            <b-form-textarea
              id="notes"
              v-model="newDrill.how"
              placeholder="How do you do the drill"
              rows="3"
              max-rows="6"
            />
          </b-col>
        </b-row>

        <b-row style="padding-top: 8px; padding-bottom: 8px">
          <b-col sm="3">
            <label for="notes">Method:</label>
          </b-col>
          <b-col>
            <b-form-textarea
              id="notes"
              v-model="newDrill.method"
              placeholder="How the Drill is structured into an exercise method"
              rows="3"
              max-rows="6"
            />
          </b-col>
        </b-row>

        <b-row style="padding-top: 8px; padding-bottom: 8px">
          <b-col sm="3">
            <label for="notes">Difficulty Rating:</label>
          </b-col>
          <b-col sm="5">
            <b-form-rating
              for="rating-5"
              icon-empty="circle"
              icon-full="circle-fill"
              variant="secondary"
              v-model="newDrill.difficultyRating"
            ></b-form-rating>
          </b-col>
          <b-col sm="4">
            <b-form-checkbox
              id="kidFriendly"
              v-model="newDrill.kidFriendly"
              name="kidFriendly"
              >Kid Friendly?</b-form-checkbox
            >
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="3">
            <label for="notes">Media:</label>
          </b-col>
          <b-col>
            <b-form-input
              v-model="newDrill.Media.url"
              placeholder="Enter media URL"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-container>
    </b-form>

    <b-alert v-model="showIncompleteAlert" variant="danger" dismissible
      >Form is incomplete!</b-alert
    >
    <!--TODO Disable btns until they can actually do something, also hide them until a type is selected -->
    <b-button variant="success" style="float: right" @click="addDrill()"
      >Submit</b-button
    >
    <b-button variant="danger" @click="closeModel()" style="float: left"
      >Cancel</b-button
    >
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState(["team", "drills", "drillTypes", "targets"]),
  },
  data() {
    return {
      showIncompleteAlert: false,
      selectionDrills: [],
      targetIndex: 1,
      mediaFile: null,
      newDrill: {
        id: 0,
        name: "",
        type: { name: "", description: "" },
        how: "",
        description: "",
        targets: [],
        method: "",
        Media: { type: "", url: "" },
        difficultyRating: 1,
        kidFriendly: false,
      },
    };
  },
  created() {
    this.drillTypes.sort();
  },
  mounted() {
    //https://bootstrap-vue.org/docs/components/form-select
  },
  methods: {
    ...mapActions(["setTeam", "setDrills"]),
    clearFormData() {
      //resent form after submit
      return {
        //TODO
      };
    },
    addDrillInput(index) {
      if (this.drillIndex == index) {
        this.drillIndex += 1;
      }
    },
    addDrill() {
      //Push Drill onto the state
      this.determineMediaType();
      this.newDrill.id = this.drills.length + 1;

      this.drills.push(this.newDrill); //May want to move this to mutations to be more adhearnt

      var success = this.setDrills(this.drills); //Calls action
      if (success) {
        this.$root.$emit("bv::hide::modal", "modalAddDrill");
      }
    },
    determineMediaType() {
      if (this.newDrill.Media.url.includes("youtube" || "vimeo")) {
        this.newDrill.Media.type = 2;
      } else {
        this.newDrill.Media.type = 1;
      }
    },
    closeModel() {
      this.$root.$emit("bv::hide::modal", "modalAddDrill");
    },
    onOptionClick({ option, addTag }) {
      addTag(option);
      this.search = "";
    },
  },
};
</script>