
<template>
  <div>
    <b-form class="form-cont">
      <b-container class="form-template" fluid="sm">
        <b-row style="padding-top:8px;padding-bottom:8px;">
          <b-col sm="3">
            <label for="practice-datepicker">Date:</label>
          </b-col>
          <b-col>
            <b-form-datepicker id="practice-datepicker" v-model="newPractice.date" class="mb-2"></b-form-datepicker>
          </b-col>
        </b-row>

        <div v-for="index in drillIndex" :key="index">
          <b-row style="padding-top:8px;padding-bottom:8px;">
            <b-col sm="3">
              <label>Drills:</label>
            </b-col>
            <b-col>
              <b-form-select
                @change="addDrillInput(index)"
                on
                v-model="newPractice.drills[index-1]"
                :options="selectionDrills"
                fluid="sm"
              ></b-form-select>
            </b-col>
            <b-col sm="1">
              <b-icon-x-square
                class="clickable deleteIcon"
                style="top:9px;"
                variant="secondary"
                @click="removeDrill(index-1)"
              ></b-icon-x-square>
            </b-col>
          </b-row>
        </div>

        <b-row style="padding-top:8px;padding-bottom:8px;">
          <b-col sm="3">
            <label for="notes">Notes:</label>
          </b-col>
          <b-col>
            <b-form-textarea
              id="notes"
              v-model="newPractice.notes"
              placeholder="Enter Practice Notes (Optional)"
              rows="3"
              max-rows="6"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-form>

    <b-alert v-model="showIncompleteAlert" variant="danger" dismissible>Form is incomplete!</b-alert>
    <!--TODO Disable btns until they can actually do something, also hide them until a type is selected -->
    <b-button variant="success" style="float:right;" @click="addPractice()">Submit</b-button>
    <b-button variant="danger" @click="closeModel()" style="float:left;">Cancel</b-button>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState(["team", "teams", "drills", "drillTypes"]),
  },
  props: {
    practice: Object,
  },
  data() {
    return {
      showIncompleteAlert: false,
      selectionDrills: [],
      drillIndex: 1,
      editPractice: false,
      showDeleteForm: false,
      newPractice: {
        id: 0,
        date: new Date(),
        teamID: 0,
        drills: [],
        notes: "",
      },
    };
  },
  created() {
    this.getDrills(this.$route.params.teamName).then((value) => {
      this.getDrillTypes().then((value) => {
        this.populateDrillTypes();
      });
    });
  },
  mounted() {
    //https://bootstrap-vue.org/docs/components/form-select

    //If practice passed in populate the fields
    if (this.practice) {
      this.populatePractice();
    }
  },
  methods: {
    ...mapActions([
      "setTeam",
      "setPractice",
      "getDrills",
      "getDrillTypes",
      "getGlobalTargets",
    ]),
    populatePractice() {
      this.editPractice = true;
      this.newPractice.date = this.practice.date;
      this.newPractice.id = this.practice.id;
      this.newPractice.drills = this.practice.drills;
      this.newPractice.notes = this.practice.notes;

      //Index Drill for the dynamic piece
      this.drillIndex = this.practice.drills.length + 1;
    },
    populateDrillTypes() {
      //this.selectionDrills.push({ value: null, text:"Select a drill" });
      //Loop over types
      this.drillTypes.forEach((type, typeIndex) => {
        //Push this type
        console.log("type: "+type)
        this.selectionDrills.push({ label: type, options: [] });
        //Loop over Drills to see put them in their right type
        this.drills.forEach((drill, index) => {
          if (drill.type.name == type) {
            this.selectionDrills[typeIndex].options.push({
              value: drill,
              text: drill.name,
            });
          }
        });
      });
    },
    clearFormData() {
      //resent form after submit
      return {
        //TODO
      };
    },
    addDrillInput(index) {
      if (this.drillIndex == index) {
        this.drillIndex += 1;
      }
    },
    addPractice() {
      //If new, add id
      if (!this.editPractice) {
        this.newPractice.id = this.team.practices.length + 1;
        //Set time to midnight
        this.newPractice.date += "T00:00:00";
        //Push new athlete onto state
        this.team.practices.push(this.newPractice); //May want to move this to mutations to be more adhearnt
      } else {
        this.team.practices.forEach((practice, index) => {
          if (practice.id == this.newPractice.id) {
            practice.date = this.newPractice.date;
            //practice.drills = [];
            practice.drills = this.cleanDrills(this.newPractice.drills);
            practice.notes = this.newPractice.notes;
          }
        });
      }
      var success = this.setTeam({ team: this.team, teams: this.teams }); //Calls action
      if (success) {
        this.$root.$emit("bv::hide::modal", "modalAddPractice");
      }
    },
    closeModel() {
      this.$root.$emit("bv::hide::modal", "modalAddPractice");
    },
    removeDrill(index) {
      this.newPractice.drills.splice(index,1);
      this.drillIndex -= 1;
    },
    cleanDrills(drills){
      var tempDrills = drills;
      tempDrills.forEach((drill,index)=>{
        if(typeof drill =='undefined'){
          drills.splice(index,1);
        }
      });
      return drills;
    }
  },
};
</script>