<template>
	<div v-if="athlete.firstName">
		<h1>{{ athlete.firstName }} {{ athlete.lastName }}</h1>
		<p>
			<b>Category:</b>
			Youth {{ athlete.competitionGroup }} |
			<b>Age:</b>
			{{ athlete.age }} years old
			<br />
			<b>Notes:</b>
			{{ athlete.notes }}
		</p>
		<button :class="[showNewSession ? 'btn-secondary' : 'btn-purple']" @click="toggleShowData('newSession')"
			class="btn">
			Start New Session
		</button>
		<transition name="fade">
			<div v-if="showNewSession" class="newSession-cont" v-on:closeNewSession="showNewSession = false">
				<NewSession :athlete="athlete" @closeSession="toggleShowData('newSession')"
					@toggleShowType="(type) => this.toggleShowData(type)" />
			</div>
		</transition>

		<hr />
		<!--TODO This whole section should be abstracted and looped over based on number of different types of sessions -->
		<button :class="[showProgressGraphs ? 'btn-secondary' : 'btn-purple']" @click="toggleShowData('progress')" class="btn">
			{{ showProgressGraphs ? "Hide" : "Show" }} Progress Sessions
		</button>
		<transition name="fade">
			<div v-if="showProgressGraphs" class="charts-cont">
				<Charts :sessions="progressSessions" sessionType="progress" :athlete="athlete" />
			</div>
		</transition>
		<!--<button :class="[showWallGraphs ? 'btn-secondary' : 'btn-purple']" @click="toggleShowData('wall')" class="btn">
			{{ showWallGraphs ? "Hide" : "Show" }} Wall Sessions
		</button>
		<transition name="fade">
			<div v-if="showWallGraphs" class="charts-cont">
				<Charts :sessions="wallSessions" sessionType="wall" :athlete="athlete" />
			</div>
		</transition>
		<hr />
		<button :class="[showHangGraphs ? 'btn-secondary' : 'btn-purple']" @click="toggleShowData('hangboard')"
			class="btn">
			{{ showHangGraphs ? "Hide" : "Show" }} Hang Board Sessions
		</button>
		<transition name="fade">
			<div v-if="showHangGraphs" class="charts-cont">
				<Charts :sessions="hangSessions" sessionType="hangboard" :athlete="athlete" />
			</div>
		</transition>


		<button
      :class="[showHoldGraphs ? 'btn-secondary' : 'btn-purple']"
      @click="toggleShowData('holds')"
      class="btn"
    >{{ showHoldGraphs ? 'Hide' : 'Show' }} Hold Sessions</button>
    <transition name="fade">
      <div v-if="showHoldGraphs" class="charts-cont">
        <Charts :sessions="holdSessions" sessionType="holds" />
      </div>
    </transition>-->
	</div>
</template>


<script type="text/javascript">
import { mapState, mapActions, mapGetters } from "vuex";
import Charts from "@/components/sessionCharts.vue";
import NewSession from "@/components/newSession.vue";

export default {
	data() {
		return {
			loading: true,
			wallSessions: [],
			progressSessions: [],
			hangSessions: [],
			showWallGraphs: false,
			showHangGraphs: false,
			showNewSession: false,
			showProgressGraphs: false,
		};
	},
	computed: {
		...mapState(["athlete"]),
	},
	created() {
		this.getAthlete({
			athleteID: this.$route.params.id,
			teamName: this.$route.params.teamName,
		}).then((value) => {
			//Create route
			this.updateRouting({
				depth: 4,
				path: this.$route.path,
				name: this.athlete.firstName,
			});
		});
		//Listener from Charts child component, helps the child component re-render
		this.$on("deleteSession", (section) => {
			this.setSessionTypes();
		});
	},
	methods: {
		...mapActions(["getAthlete"]),
		...mapActions(["updateRouting"]),
		toggleShowData(type) {
			//Set the data
			this.setSessionTypes();
			//Now show the data
			type = type.toLowerCase();
			switch (type) {
				case "wall":
					this.showWallGraphs = !this.showWallGraphs;
					break;
				case "holds":
					this.showHoldGraphs = !this.showHoldGraphs;
					break;
				case "newsession":
					this.showNewSession = !this.showNewSession;
					break;
				case "hangboard":
					this.showHangGraphs = !this.showHangGraphs;
					break
				case "progress":
					this.showProgressGraphs = !this.showProgressGraphs;
					break;
			}
		},
		setSessionTypes() {
			this.athlete.allSessions.forEach((sessionType) => {
				if ("progress" === sessionType.type.toLowerCase()) {
					this.progressSessions = sessionType.sessions;
				}
				else if ("wall" === sessionType.type.toLowerCase()) {
					this.wallSessions = sessionType.sessions;
				} else if ("hangboard" === sessionType.type.toLowerCase()) {
					this.hangSessions = sessionType.sessions;
				}
			});
		},
	},
	components: {
		Charts,
		NewSession,
	},
	mounted() { },
};
</script>
<style scoped lang="scss">
.charts-cont {
	padding: 6px 0px;
}
</style>
