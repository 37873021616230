<template>
	<div class="contact-view-container">
		<h1>Bloc Yard Youth Teams</h1>
		<b-nav pills align="center" variant="purple">
			<b-nav-item to="/about" text-variant="purple">Youth Teams
			</b-nav-item>
			<b-nav-item to="/about/comp" tex-variant="purple">Competitive Team
			</b-nav-item>
			<b-nav-item to="/about/dev" tex-variant="purple">Development Team
			</b-nav-item>
		</b-nav>
		<div class="mainContainer">

			<!-- Child route gets rendered in <router-view> or <nuxt-child> -->
			<router-view></router-view>
		</div>

		<b-button v-if="!offseason" :class="[showSignUp ? 'btn-secondary' : 'btn-purple']" class="btn"
			@click="showSignUp = !showSignUp"><a href="#showSignUp" style="text-decoration: none; color: #fff">Sign
				up?</a></b-button>
		<div id="showSignUp" v-if="showSignUp">
			<hr />
			<SignUp />
		</div>
	</div>
</template>


<script>
import { mapActions } from "vuex";
import "../assets/sass/_contact.scss";
import SignUp from "@/components/signup.vue";
import AboutTeams from "@/components/aboutTeams.vue";

export default {
	components: {
		SignUp,
		AboutTeams
	},
	data() {
		return {
			showSignUp: false,
			offseason: true,
		};
	},
	computed: {},
	created() {
		this.defaultRoute();
	},
	methods: {
		...mapActions(["sendContactForm"]),
		...mapActions(["defaultRoute"]),
	},
};
</script>
