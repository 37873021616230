<template>
  <div>
    <h1>Athletes</h1>
    <b-table
      striped
      hover
      :items="dataItems"
      :fields="fields"
      responsive="sm"
      :sort-compare="sortCompGroup"
    >
      <template v-slot:cell(name)="data">
        <router-link
          :to="`/${teamName}/Athletes/${data.item.id}`"
        >{{ data.item.firstName }} {{ data.item.lastName }}</router-link>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  data() {
    //This is how you limit what fields are shown
    return {
      fields: [
        {
          key: "name",
          label: "Full Name",
          sortable: true
        },
        {
          key: "competitionGroup",
          label: "Competition Group",
          sortable: true
        },
        { key: "age", sortable: true }
      ]
    };
  },
  props: {
    dataItems: Array,
    teamName: String
  },
  created() {
    //Calculate age and competitionGroup, then add them to the model
  },
  methods: {
    sortCompGroup(a, b, key) {
      var aRow = a[key];
      var bRow = b[key];
      if (key == "competitionGroup") {
        if (a[key] === "Jr") {
          aRow = "1"; //Just setting the value to 1 because it comes before A in ASCII
        } else if (b[key] === "Jr") {
          bRow = "1"; //Just setting the value to 1 because it comes before A in ASCII
        }

        return compareToSort(aRow, bRow);
      } else if (key == "name") {
        var aRow = a["lastName"];
        var bRow = b["lastName"];
        return compareToSort(aRow, bRow);
      } else {
        return null;
      }
    }
  }
};
function compareToSort(aRow, bRow) {
  if (aRow < bRow) {
    return -1;
  } else if (aRow > bRow) {
    return 1;
  } else {
    return 0;
  }
  return null;
}
</script>
