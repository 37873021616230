<template>
  <div>
    <h2>Drills</h2>
    <b-table striped hover :items="dataItems" responsive="sm" :fields="fields">
      <template v-slot:cell(type)="data">
        <div
          style="margin:0px;"
          @click="populateDrillType(data.item.type)"
          class="modalLink"
          :class="[showDrillType ? 'ml-secondary' : 'ml-purple']"
        >{{data.value.name}}</div>
      </template>

      <template v-slot:cell(kidFriendly)="data">
        <span :class="[ data.value ? 'active' : 'error']">{{data.value ? "Yes" : "No"}}</span>
      </template>

      <template v-slot:cell(name)="data">
        <div
          style="margin:0px;"
          @click="populateDrill(data.item)"
          class="modalLink"
          :class="[showDrillType ? 'ml-secondary' : 'ml-purple']"
        >{{data.value}}</div>
      </template>
    </b-table>

    <!--Drill Modal -->
    <b-modal
      v-model="showDrill"
      scrollable
      id="modalDrill"
      ref="modal"
      :hideFooter="true"
    >
      <template v-slot:modal-title>
        <div style="width:75%;float:left;"><h1 s>{{drill.name}}</h1></div>
        <!--TODO make into icons -->
        <div style="width:25%;float:right;text-align: right;" class="modalHeaderRight">
          <div class="ml-purple">{{drill.kidFriendly ? "Kid Friendly" : ""}}</div>
          <div>Difficulty: {{drill.difficultyRating}}</div>
        </div>
      </template>

      <div>
        <h3 class="underline">Description</h3>
        <div v-html="drill.description" style="padding-bottom:8px; white-space: pre-line;"></div>
        
        <h3 class="underline">How</h3>
        <div v-html="drill.how" style="padding-bottom:8px;white-space: pre-line;"></div>

        <h3 class="underline">Method</h3>
        <div v-html="drill.method" style="padding-bottom:8px;white-space: pre-line;"></div>

        <div>
          <b-img v-if="drillMedia.type == 1" fluid alt="Responsive image" :src="drillMedia.url" ></b-img>
          </div>
          <div>
          <iframe v-if="drillMedia.type == 2" 
          style="width:100%;" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen 
          :src="drillMedia.url"/>
          <!--<iframe width="560" height="315" src="https://www.youtube.com/embed/7NaNt-txiqc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
          </div>
      </div>
    </b-modal>

    <!--Drill Type Modal -->
    <b-modal
      v-model="showDrillType"
      id="modalDrillType"
      ref="modal"
      title="Drill Type"
      :hideFooter="true"
    >
      <h3>{{drillType.name}}</h3>
      <p>{{drillType.description}}</p>
    </b-modal>
  </div>
</template>

<script>
import { globalUtil } from "../mixins/globalUtil.js";

export default {
  mixins: [globalUtil],
  data() {
    //This is how you limit what fields are shown
    return {
      showDrill: false,
      showDrillType: false,
      drill: {},
      drillMedia: {},
      drillType: {},
    };
  },
  props: {
    dataItems: Array,
    fields: Array,
  },
  mounted() {
    //TODO go through dataItems and figureout # of drills and If the practice is in the past
    this.addItemFields();
  },
  methods: {
    addItemFields() {},
    formatDate(value) {
      return this.getFormattedDate(new Date(value));
    },
    populateDrill(data) {
      this.drill = data;
      this.drillMedia = data.Media;
      this.showDrill = true;
    },
    populateDrillType(data) {
      this.drillType = data;
      this.showDrillType = true;
    },
  },
};
</script>
<style scoped>
.underline {
  border-bottom: 2px solid #ccc;
}
p {
  margin-bottom: 1.5rem;
}
</style>