export const practiceUtil = {
    methods: {
        isFinished(date){
            var inDate = new Date(date);
            var today = new Date();
            var yesterday = new Date();
            yesterday.setDate(today.getDate()-1);
            if(inDate < yesterday){
              return true;
            }
            return false;
          },
    }
}