<template>
	<div class="contact-view-container">
		<b-form v-if="!submitSuccess" class="form-cont">
			<b-container class="form-template" fluid="md">
				<h1 style="text-align: center">Sign Up</h1>
				<b-row class="my-2">
					<h3>Parents Information</h3>
				</b-row>
				<b-row class="my-2">
					<b-col md="2">
						<label for="parentFirstName">Parents First Name:</label>
					</b-col>
					<b-col md="4">
						<b-form-input id="parentFirstName" v-model="contactForm.parentFirstName"
							placeholder="First Name" type="text" />
					</b-col>
					<b-col md="2">
						<label for="parentLastName">Parents Last Name:</label>
					</b-col>
					<b-col md="4">
						<b-form-input id="parentLastName" v-model="contactForm.parentLastName" placeholder="Last Name"
							type="text" />
					</b-col>
				</b-row>
				<b-row class="my-2">
					<b-col md="2">
						<label for="email">Email:</label>
					</b-col>
					<b-col md="4">
						<b-form-input id="email" v-model="contactForm.email" placeholder="Enter contact email"
							type="text" />
					</b-col>
					<b-col md="2">
						<label for="phone">Phone:</label>
					</b-col>
					<b-col md="4">
						<b-form-input id="phone" v-model="contactForm.phone" placeholder="Enter contact phone number"
							type="text" />
					</b-col>
				</b-row>

				<hr />
				<b-row class="my-2">
					<h3>Athletes Information</h3>
				</b-row>
				<b-row class="my-2">
					<b-col md="2">
						<label for="athletfirstName">Athletes First Name:</label>
					</b-col>
					<b-col md="4">
						<b-form-input id="athletfirstName" v-model="contactForm.athlete_firstName"
							placeholder="Athletes First Name" type="text" />
					</b-col>
					<b-col md="2">
						<label for="lastName">Athletes Last Name:</label>
					</b-col>
					<b-col md="4">
						<b-form-input id="lastName" v-model="contactForm.athlete_lastName"
							placeholder="Athletes Last Name" type="text" />
					</b-col>
				</b-row>
				<b-row class="my-2">
					<b-col md="2">
						<label for="gender">Gender:</label>
					</b-col>
					<b-col md="4">
						<b-form-select id="gender" v-model="contactForm.athlete_gender" :options="genderOptions">
						</b-form-select>
					</b-col>
					<b-col md="2">
						<label for="dob">Date of Birth:</label>
					</b-col>
					<b-col md="4">
						<b-form-input id="dob" v-model="contactForm.athlete_dob" type="date" />
					</b-col>
				</b-row>
				<b-row class="my-2">
					<b-col md="2">
						<label for="teamName">Team:</label>
					</b-col>
					<b-col md="4">
						<b-form-select id="teamName" v-model="contactForm.teamName" :options="teamOptions">
						</b-form-select>
					</b-col>
					<b-col md="2">
						<label for="jerseySize">Jersey Size:</label>
					</b-col>
					<b-col md="4">
						<b-form-select id="jerseySize" v-model="contactForm.athlete_jerseySize" :options="jerseySizeOptions">
						</b-form-select>
					</b-col>
				</b-row>
				<b-row>
					<b-col md="2">
						<label for="message">Additional Message:</label>
					</b-col>
					<b-col sm="10">
						<b-form-textarea id="message" placeholder="Any additional thoughts or questions?"
							v-model="contactForm.message"></b-form-textarea>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
		<b-alert v-model="showIncompleteAlert" variant="danger" dismissible>Form is incomplete!</b-alert>
		<!--TODO Disable btns until they can actually do something, also hide them until a type is selected -->
		<b-button v-if="!submitSuccess" variant="purple" @click="submit()">Submit</b-button>

		<div v-if="submitSuccess">
			<h3>Form was submitted successfully!</h3>
			<p>
				Thank you for your interest in the Bloc Yard Youth Team! <br />One of
				the coaches will reach out to you as soon as possible to confirm your
				submission and answer any quesitons you might have.
			</p>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import "../assets/sass/_contact.scss";
import { athleteUtil } from "../mixins/athleteUtil.js";

export default {
	mixins: [athleteUtil],
	data() {
		return {
			showIncompleteAlert: false,
			submitSuccess: false,
			contactForm: {
				athlete_firstName: "",
				athlete_lastName: "",
				athlete_DOB: "",
				athlete_gender: "",
				athlete_age: 0,
				athlete_competitionGroup: "",
				parentFirstName: "",
				parentLastName: "",
				competitionGroup: "",
				phone: "",
				email: "",
				message: "",
				teamName: "",
				athlete_jerseySize: ""
			},
			genderOptions: [
				{ value: "male", text: "Male" },
				{ value: "female", text: "Female" },
			],
			teamOptions: [
				{ value: "Competitive", text: "Competitive" },
				{ value: "Development", text: "Development" },
				{ value: "Not Sure", text: "Not Sure" },
			],
			jerseySizeOptions:[
				{ value: "Youth Small", text: "Youth Small" },
				{ value: "Youth Medium", text: "Youth Medium" },
				{ value: "Youth Large", text: "Youth Large" },
				{ value: "Youth X-Large", text: "Youth X-Large" },
				{ value: "Adult Small", text: "Adult Small" },
				{ value: "Adult Medium", text: "Adult Medium" },
				{ value: "Adult Large", text: "Adult Large" },
				{ value: "Adult X-Large", text: "Adult X-Large" },
			]
		};
	},
	computed: {},
	created() {
		this.defaultRoute();
	},
	methods: {
		...mapActions(["sendContactForm"]),
		submit() {
			this.contactForm.athlete_age = this.getAge(this.contactForm.athlete_dob);
			this.contactForm.athlete_competitionGroup = this.getCompGroup(
				this.contactForm.athlete_dob
			);

			//TODO send email
			var success = this.sendContactForm(this.contactForm); //Calls action
			if (success) {
				this.submitSuccess = true;
			} else {
			}
		},
		...mapActions(["defaultRoute"]),
	},
};
</script>
