<!--
There is a fundamental.. issue with how we are populating sessionData.
Its realing on the order of the categories never changing.
-->
<template>
  <div>
    <b-form class="form-cont">
      <b-container class="form-template" fluid="sm">
        <b-row style="padding-top:8px;padding-bottom:8px;">
          <b-col sm="3">
            <label for="firstName">First Name:</label>
          </b-col>
          <b-col sm="9">
            <b-form-input
              id="firstName"
              v-model="newAthlete.firstName"
              placeholder="Enter athletes First Name"
              type="text"
            />
          </b-col>
        </b-row>
        <b-row style="padding-top:8px;padding-bottom:8px;">
          <b-col sm="3">
            <label for="lastName">Last Name:</label>
          </b-col>
          <b-col sm="9">
            <b-form-input
              id="lastName"
              v-model="newAthlete.lastName"
              placeholder="Enter athletes Last Name"
              type="text"
            />
          </b-col>
        </b-row>
        <b-row style="padding-top:8px;padding-bottom:8px;">
          <b-col sm="3">
            <label for="gender">Gender:</label>
          </b-col>
          <b-col sm="9">
            <b-form-select
              id="gender"
              v-model="newAthlete.gender"
              placeholder="Enter athletes Gender"
              :options="genderOptions"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row style="padding-top:8px;padding-bottom:8px;">
          <b-col sm="3">
            <label for="dob">Date of Birth:</label>
          </b-col>
          <b-col>
            <b-form-input
              id="dob"
              v-model="newAthlete.dob"
              placeholder="Enter athletes DOB"
              type="date"
            />
          </b-col>
        </b-row>
        <b-row style="padding-top:8px;padding-bottom:8px;">
          <b-col sm="3">
            <label for="notes">Notes:</label>
          </b-col>
          <b-col>
            <b-form-input
              id="notes"
              v-model="newAthlete.notes"
              placeholder="Enter athletes Notes (Optional)"
              type="text"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-form>

    <b-alert v-model="showIncompleteAlert" variant="danger" dismissible>Form is incomplete!</b-alert>
    <!--TODO Disable btns until they can actually do something, also hide them until a type is selected -->
    <b-button variant="purple" @click="addAthlete()">Submit</b-button>
    <b-button variant="danger" @click="closeModel()" style="float:left;">Cancel</b-button>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { athleteUtil } from "../mixins/athleteUtil.js";

export default {
  mixins: [athleteUtil],
  props: {
    team: Object
  },
  data() {
    return {
      showIncompleteAlert: false,
      newAthlete: {
        id: 0,
        teamFileName:this.team.fileName,
        firstName: "",
        lastName: "",
        gender: "",
        dob:"",
        age: 0,
        competitionGroup: "",
        notes: "",
        allSessions: []
      },
      genderOptions: [
        { value: "male", text: "Male" },
        { value: "female", text: "Female" }
      ],
      competitionGroupOptions: [
        { value: "D", text: "D" },
        { value: "C", text: "C" },
        { value: "B", text: "B" },
        { value: "A", text: "A" },
        { value: "JR", text: "JR" }
      ]
    };
  },
  computed: {
    //...mapState(["team"])
  },
  beforeMount() {},
  mounted() {},
  methods: {
    ...mapActions(["setTeam","setAthlete","setTeams"]),
    clearFormData() {
      //resent form after submit
      return {
        //TODO
      };
    },
    addAthlete() {
      //TODO set New Id
      this.newAthlete.id = this.team.athletes.length + 1;
      this.newAthlete.age = this.getAge(this.newAthlete.dob);
      this.newAthlete.competitionGroup = this.getCompGroup(this.newAthlete.dob);

      //Push new athlete onto state
      this.team.athletes.push(this.newAthlete); //May want to move this to mutations to be more adhearnt

      var success = this.setTeam({ team: this.team, teams: this.teams }); //Calls action
      var success2 = this.setAthlete({ teamName: this.team.fileName, athlete: this.newAthlete }); //Calls action
      if (success && success2) {
        this.$root.$emit("bv::hide::modal", "modalAddAthlete");
      }
    },
    closeModel(){
      this.$root.$emit('bv::hide::modal', 'modalAddAthlete')
    }
  }
};
</script>