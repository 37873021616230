import Team from "../api/team";
import Athlete from "../api/athlete";
import Ancillary from "../api/ancillary";
import Email from "../emailService/send";

/*
The main set of actions for all components.
In the future we may want to split these out into their own files if it gets to large.
Then just combine the files here.

*/
export default {
	defaultRoute({ commit }) {
		commit('deafultRoute');
	},
	updateRouting: ({ commit }, payload) => {
		commit('updateRouting', payload);
	},
	updateSession: ({ commit }, payload) => { //commit comes from vuex contex
		//We want to update the team state with update session
		commit('updateSession', payload); //Calls a mutation
	},
	updateSessions: ({ commit }, payload) => {
		commit('updateSessions', payload);
	},
	addSession: ({ commit }, payload) => { //commit comes from vuex contex
		commit('addSession', payload); //Calls a mutation
	},
	getTeam({ commit }, teamName) {
		return Team.getTeam(teamName).then(response => {
			var team = response.data;
			team.athletes.forEach(athlete => {
				commit('getAge', { "athlete": athlete });
				commit('getCompGroup', { "athlete": athlete });
			});
			commit('setTeamState', team);
		});
	},
	getTeams({ commit }) {
		Team.getTeams().then(response => {
			commit('setTeamsState', response.data.teams);
		});
	},
	setTeams({ commit }, teamsState) {
		Team.setTeams(teamsSate).then(response => {
			//TODO Save new date, check if current save date is older then files save date. If so, set
			commit('setTeamsState', response.data.teams);
		});
	},
	getAthlete({ commit }, payload) {
		return Athlete.getAthlete(payload.athleteID, payload.teamName).then(response => {
			commit('getAge', { "athlete": response.data });
			commit('getCompGroup', { "athlete": response.data });
			commit('setAthleteState', response.data);
		});

	},
	setAthlete: ({ commit }, payload) => { //commit comes from vuex contex
		Athlete.setAthlete(payload.athlete, payload.teamName).then(response => {
			commit('setAthleteState', payload.athlete)
		});
	},
	setTeam({ commit }, teamState) {
		//TODO set Team State, also set Teams state
		Team.setTeam(teamState).then(response => {
			//TODO Save new date, check if current save da
		});
	},
	getPractice({ commit }, payload) {
		return Team.getTeam(payload.teamName).then(response => {
			commit('setTeamState', response.data);
			return commit('setPracticeState', { practiceID: payload.practiceID, team: response.data });
		});
	},
	getGlobalTargets({ commit }, payload) {
		return Ancillary.getGlobalTargets().then(response => {
			commit('setTargets', response.data.targets);
		});
	},
	getDrills({ commit }, payload) {
		return Ancillary.getDrills().then(response => {
			commit('setDrills', response.data.drills);
		});
	},
	setDrills({ commit }, drills) {
		Ancillary.setDrills(drills).then(response => {
			commit('setDrills', response.data.drills);
		});
	},
	getDrillTypes({ commit }, payload) {
		return Ancillary.getDrillTypes().then(response => {
			commit('setDrillTypes', response.data.drillTypes);
		});
	},
	sendContactForm({ commit }, data) {
		return Email.sendEmail(data).then(response => {
			commit('sendEmail', response.data);
		});
	}
}
