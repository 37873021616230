<template>
  <div>
    <h2>Teams</h2>
    <b-table
      striped
      hover
      :items="dataItems"
      :fields="fields"
      responsive="sm"
    >
      <template v-slot:cell(displayName)="data">
        <router-link
          :to="`${data.item.fileName}`"
        >{{ data.item.displayName }}</router-link>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  data() {
    //This is how you limit what fields are shown
    return {
      fields: [
        {
          key: "displayName",
          label: "Team Name",
          sortable: true
        },
        {
          key: "numOfAthletes",
          label: "# of Athletes",
          sortable: true
        }
      ]
    };
  },
  props: {
    dataItems: Array
  },
  methods: {

  }
};
/*function compareToSort(aRow, bRow) {
  if (aRow < bRow) {
    return -1;
  } else if (aRow > bRow) {
    return 1;
  } else {
    return 0;
  }
  return null;
}*/
</script>
